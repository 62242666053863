import { render } from "./agreement.vue?vue&type=template&id=35a10a84&scoped=true"
import script from "./agreement.vue?vue&type=script&lang=js"
export * from "./agreement.vue?vue&type=script&lang=js"

import "./agreement.vue?vue&type=style&index=0&id=35a10a84&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-35a10a84"
/* hot reload */
if (module.hot) {
  script.__hmrId = "35a10a84"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('35a10a84', script)) {
    api.reload('35a10a84', script)
  }
  
  module.hot.accept("./agreement.vue?vue&type=template&id=35a10a84&scoped=true", () => {
    api.rerender('35a10a84', render)
  })

}

script.__file = "src/views/static/agreement/agreement.vue"

export default script