<template>
    <div class="agreement">
        <headBack>
            <template v-slot:title>
                <div class='title'>
                    医护到家医护服务协议
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
        <div class="yhdj services_block">
            <p><br>
            <strong>【协议说明】</strong>北京医护到家健康管理集团有限公司（以下简称“医护到家”）专门为医生/护士/护工（以下简称“医护人员”或“您”）开发的移动应用，您可使用它设置您的执业信息、接受预约并提供在线问诊或上门服务。本协议由医护到家与您订立，明确双方的权利义务，请您在下载、安装及使用本应用前仔细阅读协议条款。一经注册或以其他方式使用我们的产品和服务，将视为您已经确认接受本协议条款并同意成为本协议的一方，若您不同意任一条款请卸载并停止使用本应用。就相关事宜，双方达成如下一致：<br>
            <br>
            <strong>【审慎阅读】您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。如您对协议有任何疑问，可向医护到家客服咨询。如您未满18周岁，或以其他形式被限制民事行为能力，请在监护人的陪同下阅读本协议。<br>
            </strong><br>
            <strong>【词汇定义】</strong>本文中所用词汇定义如下：<br>
            <br>
            医护到家：指北京医护到家健康管理集团有限公司推出的移动医疗健康服务信息平台，为全国患者提供护士上门、中医慢病、居家养老等信息服务。本平台包括医护到家网站、医护到家移动客户端及其他现在或将来推出的基于医护到家的信息平台。<br>
            <br>
            <strong>医护</strong>：指阅读并同意本协议内容，经过医护到家注册程序成为为患者提供服务的医生、护士或护工等。（以下更多称为“您”）<br>
            <br>
            <strong>在线问诊</strong>：指通过医护到家平台，医生就平台患者描述的病情，利用医学知识及临床经验给予一定的在线解惑及如何就医方面的建议。<br>
            <br>
            <strong>上门服务</strong>：指注册用户通过医护到家平台，预约医生/护士/护工到指定地点，就用户病情咨询提供相关健康服务，包括但不限于护士上门、中医慢病、居家养老等服务。<br>
            <br>
            <strong>医护到家管理规定</strong>：指包括本协议在内的，由医护签署，或由医护到家在明显位置展示的，具有规范医护行为作用的各类规定、提示、声明文件。<br>
            <br>
            <strong>1. 注册条件</strong><br>
            <br>
            1.1医护到家（医护版）仅接受正规医院执业医生/护士，或具有一定经验的护工的实名注册申请，您应保证在注册和使用本软件期间是具备完全民事权利能力和行为能力的自然人，并持续持有合法有效的中华人民共和国《执业医生资格证》或者《护士执业资格证》或者持有护工上岗证等相关资质/证明文件。<br>
            <br>
            1.2若您不具备上述主体资格，则应终止注册及使用本应用。医护到家若发现您不满足注册条件时有权随时单方解除本协议并注销您的账户，您应当承担因此而导致的一切后果及相关责任。<br>
            <br>
            <strong>2. 注册信息</strong><br>
            <br>
            2.1 您在注册账户时应向医护到家提供完整、真实且有效的注册信息（包括但不限于您的个人身份信息、执业信息、职称信息等）及资质文件（包括但不限于身份证明文件、执业资质证明文件、职称证明材料等），接受并配合医护到家对您身份信息和基本资质文件表面真实性的审核；<br>
            <br>
            2.2 医护到家将对您的身份信息、执业资质、职称等级信息、所在医院及所在科室信息的表面真实性进行审核，并会把经认证的信息展现在“医护到家”中，您应确保经医护到家认证的信息在您使用本软件期间持续真实、合法、准确、有效。若发生变更，您应当在变更后3个工作日内向医护到家提供更新后的信息和相应的证明文件，并接受医护到家的重新验证审核。<br>
            <br>
            2.3 您必须基于实际情况填写擅长的领域及执业经历等信息，确保信息真实可信，不得有夸大宣传的言词。<br>
            <br>
            <strong>3.权限开通</strong><br>
            <br>
            3.1 您的注册信息通过医护到家审核后，医护到家将为您的账户开通本应用的使用权限。根据使用权限，您可以通过登录医护到家账号使用本应用的各项功能。由于医疗服务的特殊性，您应确保仅有您本人能登录并操作医护到家账户， 您无权将账户转租、转借、赠与或授权任何第三方使用，否则您应当承担由此产生的一切后果并赔偿相关所有损失。<br>
            <br>
            3.2 您须自行对您的用户名和密码的安全性负责，并对您账户下的一切使用行为承担全部责任。若您在任何情况下知悉或怀疑您的用户名及密码为任何第三方所知悉，或有人未经授权而使用您的账号， 您应当立即以有效方式通知医护到家，由医护到家工作人员帮您分析解决，确保您的账号安全。<br>
            <br>
            <strong>4.使用规范</strong><br>
            <br>
            4.1 医护到家为您提供展示执业信息的平台，您须对您提供的个人信息、执业信息等资料的真实性、合法性负全部责任，确保患者可以基于您真实的信息进行判断和选择；<br>
            <br>
            4.2 医护到家平台中包含了“在线问诊”和“预约上门”功能，您应本着最大诚信的原则设置出诊时间，接受或拒绝预约并按承诺提供相关服务，确保患者利用本应用获得健康服务的成功率。如因您的原因导致无法完成预约服务时，您须第一时间与患者沟通并达成一致；如未与患者达成一致或受到患者投诉，首次未完成预约，医护到家将暂停您使用本应用24小时；累计两次未完成预约，医护到家将暂停您使用本应用48小时；累计超过3次未完成预约，医护到家将永久停止您使用本应用。<br>
            <br>
            4.3 医护到家包含了“在线问诊”功能，您应本着诚信原则回复患者的在线咨询，不得通过本应用进行开具处方等违反相关法律法规的诊疗行为。<br>
            <br>
            4.4 医护到家包含了“上门服务”功能，您应本着诚信原则，按照约定时间和地点，向患者提供服务，严禁私自收取约定上门服务费以外的任何费用。如出现下列问题，将参考相关处罚标准严格执行。<br>
            <br>
            4.4.1 私下交易问题。医护到家平台严禁医护人员与患者达成私下交易，如出现认证护士与患者达成私下交易行为，一经查实，立即永久封停该医护人员认证账号。平台鼓励患者或家属对私下交易行为进行积极举报，并根据患者或家属匿名举报信息给予举报人300～1000元不等的奖励。<br>
            <br>
            4.4.2 私下乱收费问题。除医护到家平台对外公布的具有明确价格范围的相关工具物品费或夜间交通补助费外，医护到家平台严禁医护人员存在向患者或家属私下加价或者其他任何乱收费行为，一经查实，立即永久封停该医护人员认证账号。如因医护人员私下收费构成违法犯罪的，将移交公安司法机关处理。<br>
            <br>
            4.4.3 未按时上门问题。如平台认证的医护人员未按照约定时间进行上门服务的，包括但不限于迟到、临时更改时间、无法继续上门等情况，需提前与患者或家属道歉并争取获得谅解。如不能与患者或家属达成一致的，需第一时间联系医护到家平台客服人员（客服电话：400-800-6996），平台将介入进行协调。对于未按时上门的医护人员，除特殊情况外，平台将视情况给予认证医护人员限制一定时间抢约、扣除一定比例上门服务费等处罚。如因医护人员未按时上门，造成用户取消预约或其他恶劣影响的，医护到家平台有权终止与该认证医护人员合作。<br>
            <br>
            4.4.4 操作不规范问题。如平台收到关于上门医护人员服务操作不规范的投诉，包括但不限于签订知情同意书、健康指导、护理操作、清洁处理等未按照ICCINO上门服务标准流程操作或未使用礼貌用语、未尽到保护患者隐私的义务等操作不规范的情况，平台将视情况给予认证医护人员限制一定时间抢约、扣除一定比例上门服务费等处罚，情况严重的平台将配合有关部门追究相关医护人员的责任。<br>
            <br>
            4.4.5 意外风险处理不积极问题。如上门服务过程中或上门服务后，出现导尿、鼻饲、吸痰、输液鼓针或其他护理等意外情况时，平台所有认证医护人员均有义务本着“一切为了患者健康”的角度出发，第一时间基于临床护理经验进行积极处理，并同步通知医护到家平台客服人员（客服电话：400-800-6996），对于意外情况妥善处理的上门医护人员，医护到家平台将给予100～2000元不等的奖励；对于出现意外情况未积极处理的医护人员，平台将永久终止合作，并追究该上门医护人员因此造成的相关责任和损失。<br>
            <br>
            4.4.6 其他违规行为。对于医护人员上门服务的其他各种违规行为，包括但不限于违反平台禁止的抗生素类、过敏类、微整形类药物注射等，因违反平台规定而产生的一些后果，均由违规医护人员承担所有责任。<br>
            <br>
            4.5 您应对任何第三方因信赖您发布的信息而遭受的损失承担赔偿责任，并应赔偿医护到家因此遭受的全部损失，包括但不限于直接损失，医护到家为此支付的律师费，鉴定费，差旅费等。<br>
            <br>
            4.6 您通过医护到家平台提供的信息服务，应严格限定在医护到家提供的健康服务范围内，不得提供医护到家服务范围以外的任何服务，包括但不限于开具处方、提供药品或输液工具、针灸等医疗服务。如因您擅自提供医护到家服务范围以外的服务导致的医患纠纷，因此产生的一切责任及全部赔偿均由您自行承担，医护到家不承担任何责任。<br>
            <br>
            <strong>5.关于参与医护到家活动所获取的奖励</strong><br>
            <br>
            您在使用医护到家过程中进行的本协议项下允许范围内的行为，如符合医护到家医生举办活动的相关奖励条件时（如医护到家平台或相关服务界面有相应的活动规则，应以该活动规则内容为准），可以享受医护到家医生给予的特定奖励，但发生如下行为时，则不在奖励范围内：<br>
            <br>
            5.1 医护到家发现您通过造假、欺诈等作弊手段骗取奖励的；<br>
            <br>
            5.2 医护到家有合理理由怀疑您提供的资料信息为错误、不实、失效或不完整的。<br>
            <br>
            如您通过上述的行为得到医护到家的奖励，请您及时将奖励返还医护到家，否则医护到家将通过法律手段追索您的不当得利。<br>
            <br>
            因运营商、个人设备硬件、操作系统、自行安装的各类软件等原因造成无法收到奖励通知，无法得到活动奖励的或因其他不可抗拒力因素而导致的无法获得奖励的，医护到家不承担任何责任，您亦无权要求医护到家给予补偿。<br>
            <br>
            <strong>6.非排他性许可</strong><br>
            <br>
            6.1 医护到家软件产品（包括但不限于本软件产品中所含的任何图象、照片、动画、文字和附加程序、修正程序、更新版本和升级版本）的一切所有权和知识产权，均由医护到家拥有。<br>
            <br>
            6.2 医护到家可撤销、非排他地授权您，依照本协议条款和条件，<br>
            <br>
            6.2.1 为在您的网站或第三方网站上使用医护到家医生服务的需要而安装、复制和使用医护到家；<br>
            <br>
            6.2.2在遵守所有的适用法律和法规的前提下，通过远程访问、浏览和下载您的医护到家的数据。<br>
            <br>
            <strong>7.权利限制<br>
            </strong><br>
            您不得自行或允许第三方：<br>
            <br>
            (a) 以任何方式创作医护到家软件或相关文档的衍生作品；<br>
            <br>
            (b) 将医护到家的产品和服务转让或再许可给他人；<br>
            <br>
            (c) 对医护到家进行反向工程、反向编译和反向汇编；<br>
            <br>
            (d) 修改或拆分医护到家的代码或者任何功能；<br>
            <br>
            (e) 使用、公布、传输或采用干扰或试图干扰医护到家医生服务或软件的运行的任何设备、软件或例程；<br>
            <br>
            (f) 侵犯用户的隐私权及任何第三方的合法权益；<br>
            <br>
            (g) 在医护到家授权范围外使用医护到家及数据、文档和报告。<br>
            <br>
            <strong>8.免责条款</strong><br>
            <br>
            8.1 您同意按医护到家的现状使用相关产品及服务。医护到家特此申明对医护到家之使用不提供任何保证（包含但不限于商业担保和特殊目的的适用性担保），亦不对您因医护到家医生服务暂停或终止/解除而遭受的任何直接或间接损失承担责任。医护到家不保证您一定能够提供服务，医护到家有权随时暂停或终止全部或部分服务。<br>
            <br>
            8.2 对于从非医护到家指定站点或发行介质上下载的医护到家，医护到家无法保证该软件是否感染计算机病毒、是否隐藏有伪装的特洛伊木马程序等黑客软件，也不承担由此给您造成的直接和间接损害责任。<br>
            <br>
            8.3 您理解医护到家中可能包含由第三方提供的服务，医护到家只是为了您的便利而提供功能模块。您使用第三方提供的服务，需与第三方另行达成服务协议，支付相应的费用并承担可能的风险。医护到家对第三方所提供的服务不承担任何形式的担保，亦不承担任何责任。<br>
            <br>
            8.4 本应用中用户向您提供的任何反馈、意见或建议均是用户的个人行为，医护到家对其言行导致的后果不承担任何责任。<br>
            <br>
            <strong>9.隐私权保护</strong><br>
            <br>
            9.1 医护到家尊重并保护您的隐私权，您注册的账户名、电子邮件地址等个人资料，非经您许可的可以展现在医护到家的部分，或根据相关法律、法规的强制性规定，以及医护到家为维护改进软件服务为目的，不会主动地泄露给第三方（隐私权政策详见隐私权保护声明）。<br>
            <br>
            9.2 您在使用该软件的过程中应当遵守法律法规中关于隐私权的保护，不得自行（且不得允许任何第三方）使用医护到家医生追踪或收集互联网用户的个人身份信息，也不得自行（且不得允许任何第三方）将从您的网站（或该第三方的网站）收集的任何数据与从任何来源获得的任何个人身份信息相联系。您应制定并遵守合法且适当的隐私权政策。<br>
            <br>
            <strong>10.费用</strong><br>
            <br>
            10.1 医护到家在线问诊功能，原则上是由医生为用户提供的免费服务，允许用户针对在线问诊服务对医生进行直接打赏，医护到家对此打赏不代扣代缴个人所得税。<br>
            <br>
            10.2 医护到家其他相关服务包括但不限于护士上门、中医慢病、居家养老等服务，医护到家将收取当次相关服务一定的服务费（原则上不超过40%），剩余费用计入上门服务医护人员的医护到家账户中。医护人员可在每月10日前对上个月的账户余额进行提现。医护到家每月将依据国家税法对医护人员上个月的收入代扣代缴个人所得税。<br>
            <br>
            10.3 医护到家有权不经另行通知而不时变更服务费用和支付的政策，变更后的政策立即生效。您应不时查阅医护到家相关政策，您继续使用医护到家的行为视为您同意医护到家变更后的所有政策，若您不同意变更事项，请终止使用本应用。<br>
            <br>
            <strong>11．保险</strong><br>
            <br>
            医护到家针对通过医护到家平台提供上门服务的全部医护人员，免费投保中国人保意外综合险和平安意外伤害险、个人责任险等险种，全部保险费用由医护到家平台承担。<br>
            <br>
            <strong>12. 商标</strong><br>
            <br>
            本《协议》不授予您与医护到家作为版权人所拥有的任何商标或服务商标有关的任何权利。除非您另行书面通知医护到家公司，否则，您特此向医护到家（包括各关联公司）授予一项有限许可，即在演示介绍、营销材料、客户名单和财务报表中允许使用您的相关信息，包括但不限于标识、商标及其他显著品牌特征等。<br>
            <br>
            <strong>13. 终止</strong><br>
            <br>
            13.1 若您违反本协议的任何条款，医护到家有权立即单方解除本协议，同时终止对您的所有授权。授权一旦终止，您使用本软件产品的权利即刻终止。此种情况下您必须停止使用医护到家，销毁医护到家认证医护及其各部分的所有副本，并在终止后三个工作日内以书面形式向医护到家做出销毁证明，否则，您将承担相应的未经授权使用医护到家软件的法律责任。<br>
            <br>
            13.2 若因您违反本协议任何条款导致医护到家单方解除本协议：<br>
            <br>
            (a) 您无权得到任何损失补偿；<br>
            <br>
            (b) 您将无权获得您的一切历史数据；<br>
            <br>
            (c) 您应赔偿因此给医护到家或任何第三方造成的损失。<br>
            <br>
            <strong>14. 不可抗力</strong><br>
            <br>
            除中国法律规定不可抗力情形外，下列情况亦属于不可抗力：(a）任何公共的互联网骨干网、网络或服务器停运；(b）您的设备、系统或当地连接服务发生故障；(c）已经预定的维修；(d）您所在地区的互联网服务中断；(e）黑客攻击。<br>
            <br>
            <strong>15.条款和政策的修改及关注义务</strong><br>
            <br>
            医护到家有权利以公告的方式修改、变更本协议条款和服务政策，您有责任定期查看。如您不接受变更、修改后的协议条款和服务政策的，应立即停止使用本软件，并按照终止条款的规定销毁已下载使用的软件产品。若您接受变更后的条款或继续使用医护到家医生的服务，表明您受变更后的协议约束。<br>
            <br>
            <strong>16. 法律适用及管辖</strong><br>
            <br>
            本协议的订立、执行和解释均适用中华人民共和国大陆地区适用之法律。因本协议或使用本软件产品所引起的争议由医护到家所有权人注册所在地的有管辖权的人民法院管辖。医护到家对本协议内容拥有最终解释权。</p>
        </div>
    </div>
</template>
<script>
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'


export default {
  name: 'agreement',
  components: {


  },
  setup () {
    let router = useRouter()
    const store = useStore()
    const route = useRoute()



    return {

        router
    }
  },

}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .content{
        margin-top:20px;
    }
    .services_block{
        padding:30px;
        font-size: 20px;
        padding-top:0;
        h3{
            font-size: 35px;
        }
    }

</style>
